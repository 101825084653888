import { ThunkAction } from 'redux-thunk';
import { getCategoryPath } from '@wix/communities-blog-client-common';
import { getQueryLocale } from '../../common/selectors/locale-selectors';
import createRequests from '../../common/services/create-requests';
import { AppState } from '../../common/types';
import { handleCategoryMenuWidgetResponse } from '../aggregated-category-menu/actions';

type FetchCategoryWidgetInitialData = ThunkAction<
  Promise<any>,
  AppState,
  { aggregatorRequest: ReturnType<typeof createRequests>['aggregatorRequest'] },
  any
>;

export const fetchCategoryWidgetInitialData =
  (): FetchCategoryWidgetInitialData =>
  async (dispatch, getState, { aggregatorRequest }) => {
    const langQuery = getQueryLocale(getState());
    const language = langQuery ? `&language=${langQuery}` : '';
    const categoryPath = getCategoryPath(getState());

    const { categoryMenu } = await aggregatorRequest<any>(
      `/v1/category-menu-widget/render-model?categoryPath=${categoryPath}${language}`,
      {
        throwOnInvalidJson: true,
      },
    );

    await dispatch(handleCategoryMenuWidgetResponse(categoryMenu));
  };
